<template>
    <div class="row wrapper border-bottom page-heading">
      <div class="col-lg-10">
        <h2 class="mb-0">{{ $t("navigations.protocol") }}</h2>
      </div>
    </div>
    <div class="wrapper wrapper-content">
      <div class="row">
            <div class="col-lg-12">
                <div class="ibox-content">
                    <h2>User Details</h2>
                    <form>
                        <div class="row">
                            <div class="col-md-4">
                                <label>Country:</label>
                                <select class="form-select">
                                    <option>Select Country</option>
                                    <option>United States</option>
                                    <option>United Kingdom</option>
                                    <option>Canada</option>
                                    <option>Germany</option>
                                    <option>France</option>
                                    <option>India</option>
                                    <option>China</option>
                                    <option>Japan</option>
                                    <option>Australia</option>
                                </select>
                            </div>
                            <div class="col-md-4">
                                <label>User Name:</label>
                                <input type="text" class="form-control" placeholder="Enter user name">
                            </div>
                            <div class="col-md-4">
                                <label>User ID:</label>
                                <input type="text" class="form-control" placeholder="Enter user ID">
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-4">
                                <label>Protocol Number:</label>
                                <input type="text" class="form-control" placeholder="Enter protocol number">
                            </div>
                            <div class="col-md-4">
                                <label>Version:</label>
                                <input type="text" class="form-control" placeholder="Enter version">
                            </div>
                            <div class="col-md-4">
                                <label>Phase:</label>
                                <select class="form-select">
                                    <option>Phase 1</option>
                                    <option>Phase 2</option>
                                    <option>Phase 3</option>
                                    <option>Phase 4</option>
                                </select>
                            </div>
                        </div>
                    </form>
                </div>

    <!-- Protocol Sections -->
    <div class="ibox-content mt-2">
        <!-- IRB Details -->
        <div class="mt-0 py-0 px-3">
            <h4 class="pt-0">Section 1 - IRB Details</h4>
            <div class="row border rounded">
                <div class="col-lg-12 my-2">
                    <label class="col-form-label">Assign Team Member:</label>
                    <input type="text" class="form-control" placeholder="Enter team member name">
                </div>
                <div class="col-lg-12 my-2">
                    <label class="col-form-label">Approval Status:</label>
                    <select class="form-select">
                        <option>Pending</option>
                        <option>Approved</option>
                        <option>Rejected</option>
                    </select>
                </div>
                <div class="col-lg-12 my-2">    
                    <label class="col-form-label">Upload IRB Document:</label>
                    <input type="file" class="form-control">
                </div>
                <div class="col-lg-12 my-2  text-right">
                    <a href="" class="text-dark"> <i class="fa fa-circle label-round-green"></i> Final Approved Version </a>
                </div>
            </div>    
        </div>
        
        <!-- Investigator Details -->
        <div class="mt-4 py-0 px-3">
            <h4 class="pt-0">Section 2 - Investigator Details</h4>
            <div class="row border rounded">
                <div class="col-lg-12 my-2">
                    <label class="col-form-label">Assign Investigator:</label>
                    <input type="text" class="form-control" placeholder="Enter investigator name">
                </div>
                <div class="col-lg-12 my-2">
                    <label class="col-form-label">Approval Status:</label>
                    <select class="form-select">
                        <option>Pending</option>
                        <option>Approved</option>
                        <option>Rejected</option>
                    </select>
                </div>
                <div class="col-lg-12 my-2">    
                    <label class="col-form-label">Upload Investigator Report:</label>
                    <input type="file" class="form-control">
                </div>
                <div class="col-lg-12 my-2 text-right">
                    <a href="" class="text-dark"> <i class="fa fa-circle label-round-green"></i> Final Approved Version </a>
                </div>
            </div>    
        </div>
         <!-- Sponsor Details -->
         <div class="mt-4 py-0 px-3">
            <h4 class="pt-0">Section 3 - Sponsor</h4>
            <div class="row border rounded">
                <div class="col-lg-12 my-2">
                    <label class="col-form-label">Assign Sponsor:</label>
                    <input type="text" class="form-control" placeholder="Enter sponsor name">
                </div>
                <div class="col-lg-12 my-2">
                    <label class="col-form-label">Approval Status:</label>
                    <select class="form-select">
                        <option>Pending</option>
                        <option>Approved</option>
                        <option>Rejected</option>
                    </select>
                </div>
                <div class="col-lg-12 my-2">    
                    <label class="col-form-label">Upload Sponsor Agreement:</label>
                    <input type="file" class="form-control">
                </div>
                <div class="col-lg-12 my-2 text-right">
                    <a href="" class="text-dark"> <i class="fa fa-circle label-round-green"></i> Final Approved Version </a>
                </div>
            </div>    
        </div>
        
        <!-- Data Governance -->
        <div class="mt-4 py-0 px-3">
            <h4 class="pt-0">Section 4 - Data Governance</h4>
            <div class="row border rounded">
                <div class="col-lg-12 my-2">
                    <label class="col-form-label">Assign Data Manager:</label>
                    <input type="text" class="form-control" placeholder="Enter data manager name">
                </div>
                <div class="col-lg-12 my-2">
                    <label class="col-form-label">Approval Status:</label>
                    <select class="form-select">
                        <option>Pending</option>
                        <option>Approved</option>
                        <option>Rejected</option>
                    </select>
                </div>
                <div class="col-lg-12 my-2">    
                    <label class="col-form-label">Upload Compliance Report:</label>
                    <input type="file" class="form-control">
                </div>
                <div class="col-lg-12 my-2 text-right">
                    <a href="" class="text-dark"> <i class="fa fa-circle label-round-green"></i> Final Approved Version </a>
                </div>
            </div>    
        </div>
    </div>

    <!-- Review & Approval Section -->
    <div class="ibox-content mt-2">
        <h2>Final Review & Approval</h2>
        <form>
            <div class="mb-3">
                <label class="col-form-label">Reviewer Name:</label>
                <input type="text" class="form-control" placeholder="Enter reviewer name" required>
            </div>
            <div class="mb-3">
                <label class="col-form-label">Approval Date:</label>
                <input type="date" class="form-control">
            </div>
            <div class="mb-3">
                <label class="col-form-label">Comments:</label>
                <textarea class="form-control" rows="3" placeholder="Reviewer comments..."></textarea>
            </div>
            <button class="btn btn-default px-5 py1">Approve</button>
            <button class="btn btn-primary px-5 py1">Request Changes</button>
        </form>
    </div>
            </div>
      </div>
    </div>
  </template>
  
  <style scoped>
  .nav-tabs {
      border-bottom: 1px solid #6660fe;
  }
  .nav-tabs li a {
    padding: 5px 20px;
    cursor: pointer;
    border: solid 0px #ffffff;
    border-radius: 10px 10px 0px 0px;
  }
  
  .activeTab {
    color: #ffffff !important;
    background-color: #6660fe;
    border-color: #6660fe;
  }
  
  .ibox-title {
    background-color: #3c4192;
    color: rgb(95, 95, 95);
    padding: 10px;
  }
  
  .ibox-content {
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #ddd;
  }
  .form-select{
    border-radius: .5rem !important;
    font-size: .8rem !important;
  }
  .table th,
  .table td {
    vertical-align: middle;
  }
  .label {
    margin-bottom: 0px !important;
  }
  .label-round-green{
    background-color: #1ab394;
    color: #ffffff;
  }
  </style>
  