/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { setI18nLanguage, loadLocaleMessages, SUPPORT_LOCALES } from "@/i18n";
import Home from "../components/home/Home.vue";
import customerOnboarding from "../components/customerOnboarding/customerOnboarding.vue";
import studyOnboarding from "../components/studyOnboarding/studyOnboarding.vue";
import protocol from "../components/protocol/protocol.vue";
import login from "../components/login/login.vue";
import PatientLoginScreen from "../components/PatientLoginScreen/PatientLoginScreen.vue";
import PatientDashboard from "../components/PatientDashboard/PatientDashboard.vue";
import Signup from "../components/signUp/signUp.vue";
import visitsummary from "../components/visit_summary/visit_summary.vue";
import nursediary from "../components/NurseDiary/NurseDiary.vue";
import Form from "../components/form/form.vue";
import Settings from "../components/settings/settings.vue";
import listform from "../components/form_list/form_list.vue";
import formssummery from "../components/formsummery/formsummery.vue";
import formSummary from "../components/formSummary/formSummary.vue";
import showform from "../components/showform/showform.vue";
import mapform from "../components/mapForm/mapForm.vue";
import userlist from "../components/user_listing/user_list.vue";
import sitelist from "../components/site_listing/site_list.vue";
import templatelist from "../components/template_listing/template_list.vue";
import templatelisting from "../components/template_listing_all/template_list_all.vue";
import queryList from "../components/query_listing/query_List.vue";
import sentQueryList from "../components/sent_query_listing/sent_query_listing.vue";
import allQueryList from "../components/all_query_listing/all_query_listing.vue";
import general_querylist from "../components/general_querylist/general_querylist.vue";
import raiseQuery from "../components/raise_query/raise_query.vue";
import generalQuery from "../components/general_query/general_query.vue";
import viewTemplate from "../components/view_template/view_template.vue";
import viewFormTemplate from "../components/view_template_all/view_template_all.vue";
import managesite from "../components/manage_site/manage_site.vue";
import documentapprovallist from "../components/DocumentApproval/DocumentApprovalList.vue";
import patientlist from "../components/patient_listing/patient_list.vue";
import patientLoginList from "../components/patient_login_list/patient_login_list.vue";
import patientLoginPassword from "../components/Patient_password/patient_password.vue";
import managePatient from "../components/manage_patient/manage_patient.vue";
import createuser from "../components/create_user/createUser.vue";
import visitlist from "../components/visit_list/visitList.vue";
import visitforms from "../components/visitforms/visitforms.vue";
import visitSheduleList from "../components/visit_shedule/visit_shedule.vue";
import addvisit from "../components/Add_Visit/addVisit.vue";
import displayform from "../components/UI_form/formlisting.vue";
import study from "../components/study/study.vue";
import studylist from "../components/study_listing/study_list.vue";
import querymapping from "../components/query/queryMapping.vue";
import manageRole from "../components/manage_role/manage_role.vue";
import roleList from "../components/role_listing/role_listing.vue";
import userpassword from "../components/user_password/userPassword.vue";
import ResetPassword from "../components/ForgotPassword/ResetPassword.vue";
import CheckEmail from "../components/ForgotPassword/CheckEmail.vue";
import visitList from "../components/visitList/visitList.vue";
import userPrivilege from "../components/user_study_privilege/userprivilege.vue";
import roleScreen from "../components/RoleScreen/RoleScreen.vue";
import studytemplate from "../components/study_template/studyTemplate.vue";
import screen from "../components/screen/screen.vue";
import documentsummary from "../components/Document_Summary/documentSummary.vue";
import siteprivilege from "../components/siteprivilege/siteprivilege.vue";
import sitesummary from "../components/site_summary/site_dashboard.vue";
import studysummary from "../components/study_summary/study_summary.vue";
import visittemplate from "../components/visit_template/visit_template.vue";
import googlefit1 from "../components/googleFit/googlefit.vue";
import store from "../store/index";
import PatientScheduleView from "../components/PatientSchedule/PatientScheduleView.vue";
import submitForm from "../components/Submit_Form/submitForm.vue";
import StudyBasedUserList from "../components/StudyBasedUser/StudyBasedUserList.vue";
import noprivilege from "../components/noprivilegepage/noprivilege.vue";
import report from "../components/report_page/report.vue";
import patientreport from "../components/patient_report/patient_report.vue";
import reportdashboard from "../components/reports/report_dashboard.vue";
import dmssummaryreport from "../components/reports/dms_summary_report.vue";
import siteReport from "../components/report_site/site_report.vue";
import visitSummaryReport from"../components/visitSummaryReport/visitSummary_report.vue";
import editForm from "../components/editfrom/editform.vue";
import device from "../components/iot_devices/device.vue";
import patientdocument from "../components/PatientDocument/PatientDocument.vue";
import iotpatients from "../components/iot_patients/IOTPatients.vue";
import unscheduledvisits from "../components/unscheduledvisitlist/unscheduledvisits.vue";
import folder from "../components/folder/folder.vue";
import dms from "../components/generaldocument/dms.vue";
import document_patient from "../components/document_patient/document_patient.vue";
import nursesummary from "../components/nurse_summary/nurse_dashboard.vue";
import digitalform from "../components/Digital_form/digital_form.vue";
import digitalformview from "../components/View_digital_form/view_digital_form.vue"
import folderstudy from "../components/folderstudy/folderstudy.vue";
import documentview from "../components/Document_view/documentview.vue";
import viewdocument from "../components/View_Document/view_document.vue";
import reviewFormList from "../components/ReviewFormList/ReviewFormlist.vue";
import fieldprivileges from "../components/field_privileges/field_privileges.vue"
import archive from "../components/Archive/Archive.vue";
import transferSubject from "../components/transferSubject/transferSubject.vue";
import formtransfer from "../components/formtransferpage/formtransfer.vue";
import googlefitscreen from "../components/googleFitScreen/googleFit.vue";
import formdesigner from "../components/DesignForm/form_design.vue";
import formdesignertool from "../components/DesignFormAll/form_design_all.vue";
import viewform from "../components/viewform/viewform.vue";
import viewformtemplate from "../components/viewformtemplate/viewformtemplate.vue";
import VideoCall from "../components/videocall/VideoCallPage.vue"
import templateview from "../components/view_template_form/view_template_form.vue"
import profileSettings from "../components/profileSettings/profileSettings.vue"
import ConfirmationScreen from "../components/Confirmation/confirmation.vue"
import formTemplate from "../components/formTemplate/formTemplate.vue"
import studyProfile from "../components/Study_Profile/Study_Profile.vue"
import dataExport from "../components/DataExport/DataExport.vue"
import dynamicdashboard from "../components/dynamicdashboard/dynamicdashboard.vue"
import eConsent from "../components/eConsent/eConsent.vue"
import FormSuggestions from "../components/FormSuggestions/FormSuggestions.vue"
import mlCriteriaCapture from "../components/mlCriteriaCapture/mlCriteriaCapture.vue"
import test from "../components/test.vue"
import ailist from "../components/AI_List/ai_list.vue"
import survivalreport from "../components/SurvivalAnalysis/SurvivalAnalysis.vue"
import PatientScreeningReport from "../components/PatientScreeningReport/PatientScreeningReport.vue"
import PatientRetention from "../components/PatientRetention/PatientRetention.vue"
import RetentionDashboard from "../components/RetentionDashBoard/RetentionDashboard.vue"
import descriptiveStatistic from "../components/Statistic_descriptive/descriptiveStatistic.vue"
import Billing from "../components/Billing/billing.vue";

//import aiform from "../components/FormAi/";
//import FormSuggestions from "../components/FormSuggestions/FormSuggestions.vue";
//import aiform from "../components/FormAi/AiForm.vue";


function load(component: any) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`);
}
export function setupRouter(i18n: {
  mode: string;
  global: { locale: { value: any } };
}) {
  const locale =
    i18n.mode === "legacy" ? i18n.global.locale : i18n.global.locale.value;

  const routes: Array<RouteRecordRaw> = [
    {
      path: "/DescriptiveStatisticReport",
      name: "descriptiveStatistic",
      component: descriptiveStatistic,
      meta: { requiredAuth: true, roleprivilage: "Study Data Analysis Descriptive Statistic Report" },        
    },
    {
      path: "/aiList",
      name: "ailist",
      component: ailist      
    },
    {
      path: "/formTemplates",
      name: "formTemplate",
      component: formTemplate      
    },
    {
      path: "/dynamicdashboard",
      name: "dynamicdashboard",
      component: dynamicdashboard      
    },

    {
      path: "/mlCriteriaCapture",
      name: "mlCriteriaCapture",
      component: mlCriteriaCapture      
    },
    {
      path: "/test",
      name: "test",
      component: test      
    },
    {
      path: "/ConfirmationScreen",
      name: "ConfirmationScreen",
      component: ConfirmationScreen      
    },
    {
      path: "/templateview/:templateName/:tempId",
      name: "templateview",
      component: templateview,
      props: true,
      meta: { requiredAuth: true },
    },
    {
      path: "/eConsentList",
      name: "eConsent",
      component: eConsent,
      props: true,
      meta: { requiredAuth: true },
    },
    {
      path: "/survivalreport",
      name: "survivalreport",
      component: survivalreport,
      meta: { requiredAuth: true, roleprivilage: "Study Data Analysis Survival Analysis" },
    },
    {
      path: "/formdesignertool",
      name: "formdesignertool",
      component: formdesignertool,
      meta: { requiredAuth: true },
    },
    {
      path: "/formdesigner",
      name: "formdesigner",
      component: formdesigner,
      props:true,
      meta: { requiredAuth: true },
    },
    {
      path: "/googlefitscreen",
      name: "googlefitscreen",
      component: googlefitscreen,
      meta: { requiredAuth: true },
    },
    {
      path: "/archive",
      name: "archive",
      component: archive,
      meta: { requiredAuth: true },
    },
    {
      path: "/noprivilege",
      name: "noprivilege",
      component: noprivilege,
      meta: { requiredAuth: true },
    },
    {
      path: "/report",
      name: "report",
      component: report,
      meta: { requiredAuth: true },
    },
    {
      path: "/siteReport",
      name: "siteReport",
      component: siteReport,
      meta: { requiredAuth: true },
    },
    {
      path: "/visitSummaryReport",
      name: "visitSummaryReport",
      component: visitSummaryReport,
      meta: { requiredAuth: true },
    },
    {
      path: "/documentapprovallist",
      name: "documentapprovallist",
      component: documentapprovallist,
      meta: { requiredAuth: true, roleprivilage: "DMS Menu Document Approval" },
    },
    {
      path: "/patientreport",
      name: "patientreport",
      component: patientreport,
      meta: { requiredAuth: true },
    },
    {
      path: "/home",
      name: "Home",
      component: Home,
      meta: { requiredAuth: true, roleprivilage: "Dashboard Main Dashboard" },
    },
    {
      path: "/form/:isPatientVisit/:template/:study/:visitid",
      name: "Form",
      component: Form,
      props: true,
      meta: { requiredAuth: true },
    },
    {
      path: "/documentview/:referenceId/:filename/:versionId",
      name: "documentview",
      component: documentview,
      props: true,
      meta: { requiredAuth: true },
    },
    {
      path: "/viewdocument/:documentId/:versionNumber",
      name: "viewdocument",
      component: viewdocument,
      props: true,
      meta: { requiredAuth: true },
    },
    {
      path: "/",
      name: "login",
      component: login,
      meta: { requiredAuth: false },
    },
    {
      path: "/PatientLoginScreen",
      name: "PatientLoginScreen",
      component: PatientLoginScreen,
      meta: { requiredAuth: true },
    },
    {
      path: "/StudyBasedUserList",
      name: "StudyBasedUserList",
      component: StudyBasedUserList,
      meta: { requiredAuth: true, roleprivilage: "Study Item Menu Users" },
    },
    {
      path: "/PatientDashboard",
      name: "PatientDashboard",
      component: PatientDashboard,
      meta: { requiredAuth: true },
    },
    {
      path: "/reportdashboard",
      name: "reportdashboard",
      component: reportdashboard,
      meta: { requiredAuth: true, roleprivilage: "Reports Menu Report Dashboard" },
    },
    {
      path: "/dmssummaryreport",
      name: "dmssummaryreport",
      component: dmssummaryreport,
      meta: { requiredAuth: true , roleprivilage:"DMS Menu Document Summary", add: "Document Patient Approve" },
    },
    {
      path: "/signup",
      name: "Signup",
      component: Signup,
      meta: { requiredAuth: true },
    },
    {
      path: "/createuser/:userid",
      name: "createuser",
      component: createuser,
      meta: {
        requiredAuth: true,
        add: "Users Create User",
        edit: "Users Edit",
      },
      props: true,
    },
    {
      path: "/submitForm",
      name: "submitForm",
      component: submitForm,
      meta: { requiredAuth: true, roleprivilage: "Mainmenu Fill Form," },
      props: true,
    },
    {
      path: "/study/:studyid",
      name: "study",
      component: study,
      meta: {
        requiredAuth: true,
        add: "Studylist Create Study",
        edit: "Studylist Edit",
      },
      props: true,
    },
    {
      path: "/sitesummary",
      name: "sitesummary",
      component: sitesummary,
      meta: { requiredAuth: true, roleprivilage: "Dashboard Site Summary" },
    },
    {
      path: "/studysummary",
      name: "studysummary",
      component: studysummary,
      meta: { requiredAuth: true, roleprivilage: "Dashboard Study Summary" },
    },
    {
      path: "/nursesummary",
      name: "nursesummary ",
      component: nursesummary ,
      meta: { requiredAuth: true, roleprivilage: "Dashboard Nurse Dashboard" },
    },    
    {
      path: "/visitsummary",
      name: "visitsummary",
      component: visitsummary,
      meta: { requiredAuth: true, roleprivilage: "Dashboard Visit Summary" },
    },
    {
      path: "/nursediary",
      name: "nursediary",
      component: nursediary,
      meta: { requiredAuth: true, roleprivilage: "Dashboard Nurse Diary"},
    },
    {
      path: "/listform",
      name: "listform",
      component: listform,
      meta: { requiredAuth: true, roleprivilage: "Study Item Menu Submitted Forms" },
      // issue resolve
    },
    {
      path: "/reviewFormList",
      name: "reviewFormList",
      component: reviewFormList,
      meta: { requiredAuth: true, roleprivilage: "Study Item Menu Partially Filled Forms" },
      // issue resolve
    },
    {
      path: "/formssummery",
      name: "formssummery",
      component: formssummery,
      meta: { requiredAuth: true, roleprivilage: "Study Form Summary" },
      // issue resolve
    },
    {
      path: "/formSummary",
      name: "formSummary",
      component: formSummary,
      meta: { requiredAuth: true, roleprivilage: "Study Item Menu Form Summary" },
      // issue resolve
    },

    {
      path: "/showform/:closeoption/:formsesid/:fromArchive",
      name: "showform",
      component: showform,
      props: true,
      meta: { requiredAuth: true },
    },
    {
      path: "/viewform/:formsesid",
      name: "viewform",
      component: viewform,
      props: true,
      meta: { requiredAuth: true },
    },
    {
      path: "/viewformtemplate/:formsesid",
      name: "viewformtemplate",
      component: viewformtemplate,
      props: true,
      meta: { requiredAuth: true },
    },
    
    {
      path: "/editForm/:closeoption/:formsesid/:isPatientVisit",
      name: "editForm",
      component: editForm,
      props: true,
      meta: { requiredAuth: true },
    },
    {
      path: "/mapform/:formsesid",
      name: "mapform",
      component: mapform,
      props: true,
      meta: { requiredAuth: true },
    },
    {
      path: "/displayform",
      name: "displayform",
      component: displayform,
      meta: { requiredAuth: true, roleprivilage: "Study Item Menu Unassigned Form" },
    },
    {
      path: "/userlisting",
      name: "userlisting",
      component: userlist,
      meta: { requiredAuth: true, roleprivilage: "Administration Users",add:"Userlisting Audit Trails" },
    },
    {
      path: "/studylist",
      name: "studylist",
      component: studylist,
      meta: { requiredAuth: true, roleprivilage: "Mainmenu Study List" },
    },

    {
      path: "/visitlist/:stdid",
      name: "visitlist",
      component: visitlist,
      props: true,
      meta: { requiredAuth: true, roleprivilage: "Studylist Visit" ,add:"Study Audit Trails"},
    },
    {
      path: "/addvisit/:visitId",
      name: "addvisit",
      component: addvisit,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/sitelist",
      name: "sitelist",
      component: sitelist,
      meta: { requiredAuth: true, roleprivilage: "Study Item Menu Sites",add:"Sites Audit Trails" },
    },
    {
      path: "/managesite/:userid",
      name: "managesite",
      component: managesite,
      props: true,
      meta: {
        requiredAuth: true,
        add: "Sites Create Site",
        edit: "Sites Edit",
      },
    },
    {
      path: "/patientlisting",
      name: "patientList",
      component: patientlist,
      meta: { requiredAuth: true ,roleprivilage: "Subjects Audit Trails"}, 
    },
    {
      path: "/patientLoginPassword/:patientLoginId",
      name: "patientLoginPassword",
      component: patientLoginPassword,
      meta: { requiredAuth: false },
      props: true,
    },
    {
      path: "/patientLoginList",
      name: "patientLoginList",
      component: patientLoginList,
      meta: { requiredAuth: true, roleprivilage: "Study Administration Menu Patient Login" },
    },
    {
      path: "/managepatient/:patientId",
      name: "managePatient",
      component: managePatient,
      props: true,
      meta: {
        requiredAuth: true,
        add: "Subjects Create Subject",
        edit: "Subjects Edit",
      },
    },
    {
      path: "/patientlist",
      name: "patientList",
      component: patientlist,
      meta: { requiredAuth: true, roleprivilage: "Study Item Menu Subjects"  },
    },
    {
      path: "/templatelist",
      name: "templatelist",
      component: templatelist,
      meta: { requiredAuth: true, roleprivilage: "Form Library Menu Templates" },
      props: true,
    },
    {
      path: "/templatelisting",
      name: "templatelisting",
      component: templatelisting,
      meta: { requiredAuth: true, roleprivilage: "Form Library Menu Templates" },
      props: true,
    },
    {
      path: "/digitalform",
      name: "digitalform",
      component: digitalform,
      meta: { requiredAuth: true, roleprivilage: "Form Library Menu Approve Template" },
      props: true,
    },
    {
      path: "/digitalformview/:closedoption/:tempId/:formId/:comments",
      name: "digitalformview",
      component: digitalformview,
      meta: { requiredAuth: true},
      props: true,
    },
    {
      path: "/visitforms",
      name: "visitforms",
      component: visitforms,
      meta: { requiredAuth: true, roleprivilage: "Mainmenu Data Entry" },
    },
    {
      path: "/viewTemplate/:closedoption/:tempId",
      name: "viewTemplate",
      component: viewTemplate,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/viewFormTemplate/:closedoption/:tempId",
      name: "viewFormTemplate",
      component: viewFormTemplate,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/query/:closedoption/:queryid",
      name: "querymapping",
      component: querymapping,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/visittemplate",
      name: "visittemplate",
      component: visittemplate,
      meta: { requiredAuth: true, roleprivilage: "Study Administration Menu Change Schedule Template" },
      props: false,
    },
    {
      path: "/userpassword/:userid",
      name: "userpassword",
      component: userpassword,
      meta: { requiredAuth: false },
      props: true,
    },
    {
      path: "/ResetPassword/:token/:resetType/:source",
      name: "ResetPassword",
      component: ResetPassword,
      meta: { requiredAuth: false },
      props: true,
      
    },
    {
      path: "/CheckEmail",
      name: "CheckEmail",
      component: CheckEmail,
      meta: { requiredAuth: false },
    },
    {
      path: "/queryList",
      name: "queryList",
      component: queryList,
      meta: { requiredAuth: true, roleprivilage: "Query Menu Received" },
      props: false,
    },
    {
      path: "/general_querylist",
      name: "general_querylist",
      component: general_querylist,
      meta: { requiredAuth: true, roleprivilage: "Query Menu General" },
      props: false,
    },
    {
      path: "/sentQueryList",
      name: "sentQueryList",
      component: sentQueryList,
      meta: { requiredAuth: true, roleprivilage: "Query Menu Sent" },
      props: false,
    },
    {
      path: "/allQueryList",
      name: "allQueryList",
      component: allQueryList,
      meta: { requiredAuth: true, roleprivilage: "Query Menu All Queries" },
      props: false,
    },
    {
      path: "/raiseQuery/:FormId/:routeQuery/:pageno",
      name: "raiseQuery",
      component: raiseQuery,
      meta: { requiredAuth: true, roleprivilage: "Forms Form Query" },
      props: true,
    },
    {
      path: "/generalQuery",
      name: "generalQuery",
      component: generalQuery,
      meta: { requiredAuth: true, roleprivilage: "Query Menu General" },
    },
    {
      path: "/visitlist",
      name: "visitList",
      component: visitList,
      meta: { requiredAuth: true, roleprivilage: "Study Item Menu Visits" },
      props: false,
    },
    {
      path: "/userprivilege/:userid",
      name: "userPrivilege",
      component: userPrivilege,
      props: true,
      meta: { requiredAuth: true, roleprivilage: "Users User Privilege" },
    },
    {
      path: "/settings",
      name: "settings",
      component: Settings,
      meta: { requiredAuth: true },
      props: false,
    },
    {
      path: "/profileSettings",
      name: "profileSettings",
      component: profileSettings,
      meta: { requiredAuth: true },
      props: false,
    },
    {
      path: "/manageRole/:roleId",
      name: "manageRole",
      component: manageRole,
      meta: {
        requiredAuth: true,
        add: "Roles Create Role",
        edit: "Roles Edit",
      },
      props: true,
    },
    {
      path: "/roleList",
      name: "roleList",
      component: roleList,
      meta: { requiredAuth: true, roleprivilage: "Administration Roles" },
      props: false,
    },
    {
      path: "/visitSheduleList/:patientId",
      name: "visitSheduleList",
      component: visitSheduleList,
      meta: { requiredAuth: true, roleprivilage: "Subjects Schedule" },
      props: true,
    },
    {
      path: "/roleScreen",
      name: "roleScreen",
      component: roleScreen,
      meta: {
        requiredAuth: true,
        roleprivilage: "Administration Role Privileges",
      },
      props: true,
    },
    {
      path: "/screen",
      name: "screen",
      component: screen,
      meta: { requiredAuth: true, roleprivilage: "Administration Screen" },
    },
    {
      path: "/siteprivilege",
      name: "siteprivilege",
      component: siteprivilege,
      meta: {
        requiredAuth: true,
        roleprivilage: "Administration Site Privilege",
      },
    },
    {
      path: "/fieldprivileges",
      name: "fieldprivileges",
      component: fieldprivileges,
      meta: {
        requiredAuth: true,
        roleprivilage: "Administration Field Privileges",
      },
    },
    {
      path: "/studytemplate",
      name: "studytemplate",
      component: studytemplate,
      meta: { requiredAuth: true, roleprivilage: "Mainmenu Study Template" },
    },
    {
      path: "/PatientScheduleView",
      name: "PatientScheduleView",
      component: PatientScheduleView,
      meta: { requiredAuth: true, roleprivilage: "Study Administration Menu Subject Schedule" },
    },
    {
      path: "/documentsummary",
      name: "documentsummary",
      component: documentsummary,
      meta: { requiredAuth: true, roleprivilage: "DMS Menu Documents" ,add:"Document Summary Approve"},
    },
    {
      path: "/googlefit",
      name: "googlefit",
      component: googlefit1,
      meta: { requiredAuth: true },
    },
    {
      path: "/IOTDevices",
      name: "device",
      component: device,
      meta: { requiredAuth: true },
    },
    {
      path: "/patientdocument/:patientId",
      name: "patientdocument",
      component: patientdocument,
      meta: { requiredAuth: true, roleprivilage: "Subjects Add Document" },
      props: true,
    },
    {
      path: "/IOTPatients",
      name: "IOTPatients",
      component: iotpatients,
      meta: { requiredAuth: true },
    },
    {
      path: "/folderstudy/:userid",
      name: "folderstudy",
      component: folderstudy,
      meta: { requiredAuth: true ,add: "Folder Study Approve" },
      props:true,
    },
    {
      path: "/unscheduledvisits",
      name: "unscheduledvisits",
      component: unscheduledvisits,
      meta: { requiredAuth: true, roleprivilage: "Study Administration Menu Unscheduled Visits" },
      props: true,
    },
    {
      path: "/folder/:userid/:siteCode",
      name: "folder",
      component: folder,
      meta: { requiredAuth: true ,add: "Folder Approve" },
      props:true,
    },

    {
      path: "/dms",
      name: "dms",
      component: dms,
      meta: { requiredAuth: true, roleprivilage: "DMS Menu Document Upload", add: "Dms Approve" },
    },
    {
      path: "/document_patient/:userid/:siteCode", 
      name: "document_patient",
      component: document_patient,
      meta: { requiredAuth: true ,add: "Document Patient Approve" },
      props:true,
    },
    {
      path: "/transferSubject",
      name: "transferSubject",
      component: transferSubject,
      meta: {  },
      props: true,
    },
    {
      path: "/formtransfer",
      name: "formtransfer",
      component: formtransfer,
      meta: {  },
      props: true,
    },
    {
      path: "/videocall",
      name: "videocall",
      component: VideoCall,
      meta: {  },
      props: true,
    },
    {
      path: "/studyProfile",
      name: "studyProfile",
      component: studyProfile,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/dataExport",
      name: "dataExport",
      component: dataExport,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/FormSuggestions",
      name: "FormSuggestions",
      component: FormSuggestions,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/PatientScreeningReport",
      name: "PatientScreeningReport",
      component: PatientScreeningReport,
      meta: {requiredAuth: true, roleprivilage: "AI/ML Menu Patient Screening"},
    },
    {
      path: "/PatientRetention",
      name: "PatientRetention",
      component: PatientRetention,
      meta: {requiredAuth: true, roleprivilage: "AI/ML Menu Patient Retention"},
    },
    {
      path: "/RetentionDashboard",
      name: "RetentionDashboard",
      component: RetentionDashboard,
      meta: {requiredAuth: true},
    },
    {
      path: "/customerOnboarding",
      name: "customerOnboarding",
      component: customerOnboarding,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/studyOnboarding",
      name: "studyOnboarding",
      component: studyOnboarding,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/billing",
      name: "billing",
      component: Billing,
      meta: { requiredAuth: true },
      props: true,
    },
    {
      path: "/protocol",
      name: "protocol",
      component: protocol,
      meta: { requiredAuth: true },
      props: true,
    },

  ];

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
  });
  router.afterEach(async function(to, from) {
    const auth = await store.getters.authenticated;
    const privilegevalues = await store.getters.getRolesprivilegeData;
    if (auth && from.fullPath === "/" && privilegevalues == "test test,") {
      router.push(`/noprivilege`);
    }
  });
  router.beforeEach(async (to, from, next) => {
    // alert(localStorage.getItem('lang'));
    const auth = await store.getters.authenticated;
    const isactive = await store.getters.isTokenActive;
    const privilegevalues = await store.getters.getRolesprivilegeData;
    if(!isactive){
      localStorage.clear();
      location.reload();
    }
    // console.log("is authenticated ", store.getters.authenticated);
    // console.log(store.getters.getAuthData.token);
    const paramsLocale = localStorage.getItem('lang') ? localStorage.getItem('lang') : "en";
	// const paramsLocale = "en";
    if (!i18n.global["availableLocales"].includes(paramsLocale)) {
      await loadLocaleMessages(i18n, paramsLocale);
    }
    setI18nLanguage(i18n, paramsLocale);
    if(to.name!="querymapping" && to.name!="general_querylist"){
      if(store.getters.getQueryType!=''){
         store.dispatch("setQueryType", '')
      }
    }
    window.scrollTo(0, 0);
    if (auth && to.fullPath === "/" && privilegevalues == "test test,") {
      console.log("going to no privilege page ");
      return next({ path: "/noprivilege" });
    } else if (auth && to.fullPath === "/" && privilegevalues != "test test,") {
       console.log(auth);
      return next({ path: "/home" });
    } else if (!auth && to.meta.requiredAuth) {
      return next({ path: "/" });
    } else if (
      auth &&
      to.meta.roleprivilage &&
      !privilegevalues.includes(to.meta.roleprivilage)
    ) {
      return next({ path: "/noprivilege" });
    } else if (
      auth &&
      to.meta.add &&
      to.meta.edit &&
      !privilegevalues.includes(to.meta.add) &&
      !privilegevalues.includes(to.meta.edit)
    ) {
      return next({ path: "/noprivilege" });
    }
    return next();
  });
  return router;
}

