<template>
  <div>
    <!-- Invoice Form -->
    <div class="container my-2">
      <h2>Generate Invoice</h2>
      <div class="ibox-content">
        <form @submit.prevent="generateInvoice">
          <div class="row">
            <!-- Client Name -->
            <div class="col-lg-6 my-2">
              <label class="form-label">Client Name</label>
              <select id="clientNames" class="form-select" v-model="invoiceData.clientName">
                <option value="">Select</option>
                <option value="MedXcel Research">MedXcel Research</option>
                <option value="Clinovate Solutions">Clinovate Solutions</option>
                <option value="NeuroGen Trials">NeuroGen Trials</option>
                <option value="BioVista Research">BioVista Research</option>
                <option value="Synapse Clinical Studies">Synapse Clinical Studies</option>
              </select>
            </div>

            <!-- From Date -->
            <div class="col-lg-2 my-2">
              <label class="form-label">From Date</label>
              <input type="date" v-model="invoiceData.fromDate" class="form-control" required />
            </div>

            <!-- To Date -->
            <div class="col-lg-2 my-2">
              <label class="form-label">To Date</label>
              <input type="date" v-model="invoiceData.toDate" class="form-control" required />
            </div>

            <!-- Due Date -->
            <div class="col-lg-2 my-2">
              <label class="form-label">Due Date</label>
              <input type="date" v-model="invoiceData.dueDate" class="form-control" required />
            </div>
          </div>

          <!-- Services Table -->
          <div class="row pb-3" v-for="(service, index) in services" :key="index">
            <div class="col-lg-6 my-2">
              <label class="form-label">Service/Description</label>
              <input type="text" v-model="service.name" class="form-control" placeholder="Enter service provided" required />
            </div>
            <div class="col-lg-2 my-2">
              <label class="form-label">Rate (per hour/day)</label>
              <input type="number" v-model="service.rate" class="form-control" placeholder="Enter rate" min="1" required />
            </div>
            <div class="col-lg-2 my-2">
              <label class="form-label">Duration (hours/days)</label>
              <input type="text" v-model="service.duration" class="form-control" placeholder="e.g., 40 hours" required />
            </div>
            <div class="col-lg-2 my-2">
              <label class="form-label">Total Amount (USD)</label>
              <input type="number" v-model="service.totalAmount" class="form-control" placeholder="Enter amount" min="1" required />
            </div>
            <div class="col-lg-12 text-start px-0">
              <button type="button" class="btn btn-sm" @click="removeService(index)" v-if="services.length > 1">
                <i class="fa fa-trash text-danger"></i>
              </button>
            </div>
          </div>

          <!-- Add More Button -->
          <div class="col-lg-12 text-end px-0">
            <button type="button" class="btn btn-default btn-sm px-2 py-0" @click="addService">+ Add Service</button>
          </div>

          <!-- Submit Button -->
          <div class="hr-line-dashed"></div>
          <div class="col-lg-12 text-end mt-3">
            <button type="submit" class="btn btn-primary px-4 py-1">Generate Invoice</button>
          </div>
        </form>
      </div>
    </div>

    <!-- Invoice Popup -->
    <div v-if="showInvoice" class="invoice-popup">
      <div class="popup-mask" @click="closeInvoice"></div>
      <div class="popup-content">
        <div class="ibox-title style_2">
          <!-- <h5>Invoice</h5> -->
          <div class="cursor-pointer float-right" @click="closeInvoice">
            <i class="fa fa-times"></i>
          </div>
        </div>
        <div id="invoiceContent" class="invoice-content py-4">
          <div class="row mb-4">
            <div class="col-sm-6">
              <img src="../../assets/img/tnt_logo.png" alt="TNT Logo" />
            </div>
            <div class="col-sm-6 text-right">
              <address>
                5267 TNT Street, Suite 002,<br />
                Texas, MA 02113, USA<br />
                <abbr title="Phone">P:</abbr> +1 (627) 123-1234
              </address>
            </div>
            <div class="col-sm-12 text-center invoice-dtails">
              <div class="hr-line-dashed mt-1"></div>
              <h4 class="pt-1"><strong>INVOICE</strong></h4>
            </div>
            <div class="col-sm-6 text-left invoice-dtails">
              <h4><strong>To:</strong> {{ invoiceData.clientName }}</h4>
              <p><strong>Invoice No:</strong>#53745</p>
              <p><strong>Billing Period</strong> {{ formatDate(invoiceData.fromDate) }} - {{ formatDate(invoiceData.toDate) }}</p>
            </div>
            <div class="col-sm-6 text-right invoice-dtails">
              <p><strong>Invoice Date:</strong> {{ formatDate(new Date()) }}</p>
              <p><strong>Due Date:</strong> {{ formatDate(invoiceData.dueDate) }}</p>
            </div>
          </div>

          <!-- Invoice Table -->
          <table class="table invoice-table">
            <thead>
              <tr>
                <th>Service</th>
                <th>Rate</th>
                <th>Duration</th>
                <th>Total Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(service, index) in services" :key="index">
                <td>{{ service.name }}</td>
                <td>${{ service.rate }}</td>
                <td>{{ service.duration }}</td>
                <td>${{ service.totalAmount }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-end mt-3 mr-1">
            <p><strong>Subtotal:</strong> ${{ subtotal.toFixed(2) }}</p>
            <p><strong>Tax ({{ taxRate }}%):</strong> ${{ taxAmount.toFixed(2) }}</p>
            <p><strong>Grand Total:</strong> ${{ grandTotal.toFixed(2) }}</p>
          </div>
        </div>
        <div class="hr-line-dashed"></div>

        <!-- Action Buttons -->
        <div class="text-end">
          <button class="btn btn-secondary btn-sm mx-1" @click="printInvoice">Print Invoice</button>
          <button class="btn btn-primary btn-sm mx-1" @click="downloadPDF">Download PDF</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default {
  data() {
    return {
      invoiceData: {
        clientName: "",
        fromDate: "",
        toDate: "",
        dueDate: "",
      },
      services: [{ name: "", rate: "", duration: "", totalAmount: "" }],
      showInvoice: false,
      taxRate: 18, // 18% tax
    };
  },
  computed: {
    subtotal() {
      return this.services.reduce((sum, service) => sum + Number(service.totalAmount || 0), 0);
    },
    taxAmount() {
      return (this.subtotal * this.taxRate) / 100;
    },
    grandTotal() {
      return this.subtotal + this.taxAmount;
    }
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      const d = new Date(date);
      const day = String(d.getDate()).padStart(2, "0");
      const month = String(d.getMonth() + 1).padStart(2, "0");
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    },
    addService() {
      this.services.push({ name: "", rate: "", duration: "", totalAmount: "" });
    },
    removeService(index) {
      this.services.splice(index, 1);
    },
    generateInvoice() {
      this.showInvoice = true;
    },
    closeInvoice() {
      this.showInvoice = false;
    },
    downloadPDF() {
      const content = document.getElementById("invoiceContent");
      html2canvas(content).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(imgData, "PNG", 10, 10, 190, 0);
        pdf.save("invoice.pdf");
      });
    },
    printInvoice() {
  const invoiceContent = document.getElementById("invoiceContent");

  if (!invoiceContent) {
    console.error("Invoice content not found!");
    return;
  }

  // Create a new print window
  const printWindow = window.open("", "_blank");

  printWindow.document.write(`
    <html>
      <head>
        <title>Print Invoice</title>
        <!-- Include Bootstrap CSS -->
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css">
        <style>
          
          .invoice-table { width: 100%; border-collapse: collapse; }
          .invoice-table th, .invoice-table td { padding: 8px; border: 1px solid #d8d8d8; text-align: left; }
          .text-end { text-align: right; }
          .text-center { text-align: center; }
          .invoice-dtails { margin-bottom: 10px; }
        </style>
      </head>
      <body>
        <div class="container-fluid">
          ${invoiceContent.innerHTML}
        </div>
      </body>
    </html>
  `);

  printWindow.document.close();
  printWindow.focus();
  
  // Give some time for styles to load before printing
  setTimeout(() => {
    printWindow.print();
    printWindow.close();
  }, 500);
}


  }
};

</script>

<style scoped>
.invoice-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 9999;
  overflow-y: auto;
}
.invoice-table {
  width: 100%;
  border-collapse: collapse;
}



.popup-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.popup-content {
  background: white;
  max-width: 100%;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  z-index: 10001;
}
.invoice-dtails p{
  padding:0px 0px;
  margin-bottom: 0px;
}
.ibox-title{
  border: 0px !important;
  min-height: 10px !important;
  padding: 0px !important;
}
.form-select{
    border-radius: .5rem !important;
    font-family: .8rem !important;
  }

 @media print {
  body {
    font-size: 12px;
  }
  .invoice-popup {
    width: 100%;
    max-width: none;
    padding: 20px;
  }
  .popup-content {
    width: 100%;
    max-width: 100%;
    box-shadow: none;
  }
  .invoice-table th, .invoice-table td {
    padding: 8px;
    border: 1px solid #d8d8d8;
  }
}
</style>
