<template>
    <div class="row wrapper border-bottom page-heading">
      <div class="col-lg-10">
        <h2 class="mb-0">{{ $t("navigations.onboarding study") }}</h2>
      </div>
    </div>
    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-lg-12">
          <ul class="nav nav-tabs" role="tablist">
            <li v-for="(tab, index) in filteredTabs" :key="index">
              <a
                :class="{ activeTab: showBlock[index] }"
                @click="setActiveTab(index)"
                data-toggle="tab"
              >
                {{ $t(tab.label) }}
              </a>
            </li>
          </ul>
          <div role="tabpanel" v-for="(tab, index) in tabs" :key="index" v-show="showBlock[index]">
            <div class="ibox-content">
             <!-- tab 1 start -->
            <div v-if="index === 0">
              <!-- <p>{{ $t("navigations.my profile content") }}</p> -->
              <div class="form-group row">
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">{{ $t("navigations.ob study name") }}</label>
                  <input type="text" class="form-control" placeholder="" />
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">{{ $t("navigations.ob study id") }}</label>
                  <input type="text" class="form-control"  />
                </div>
                <div class="col-lg-4 my-2">
                        <label class="col-form-label">{{ $t("navigations.client/company name") }}</label>
                        <select id="selectStudy" class="form-select form-select-table">
                            <option value="">Select Client </option>
                            <option value="red">Client 1</option>
                            <option value="blue">Client 2</option>
                            <option value="green">Client 3</option>
                            <option value="yellow">Client 4</option>
                        </select>
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">{{ $t("navigations.primary contact name") }}</label>
                        <select id="selectStudy" class="form-select form-select-table">
                            <option value="">Select Study </option>
                            <option value="red">Study 1</option>
                            <option value="blue">Study 2</option>
                            <option value="green">Study 3</option>
                            <option value="yellow">Study 4</option>
                        </select>
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">{{ $t("navigations.client contact email") }}</label>
                  <select id="selectClientEmail" class="form-select form-select-table">
                            <option value="">Select Email </option>
                            <option value="red">client1@mail.com</option>
                            <option value="blue">client2@mail.com</option>
                            <option value="green">client3@mail.com</option>
                            <option value="yellow">client4@mail.com</option>
                  </select>
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">{{ $t("navigations.client contact number") }}</label>
                  <input type="text" class="form-control"  />
                </div>
                        <div class="col-lg-12 my-2">
                          <h4 class="mt-2 mb-0">Branding</h4>
                          <hr class="my-1">
                        </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                    <label for="uploadLogo">Upload Logo</label>
                      <input type="file" id="uploadLogo" class="form-control" accept=".png, .jpg, .jpeg" onchange="validateLogoFile()">
                      <small class="form-text text-muted">Accepted formats: PNG, JPG. Maximum file size: 2MB.</small>
                    <div id="logoError" class="text-danger" style="display: none;">Invalid file format or size. Please upload a valid PNG or JPG file.</div>
                  </div>
                </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label for="primaryThemeColor">Select Primary Theme Color</label>
                        <select id="primaryThemeColor" class="form-select form-select-table">
                            <option value="">Select a color</option>
                            <option value="red">Red</option>
                            <option value="blue">Blue</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label for="secondaryThemeColor">Select Secondary Theme Color</label>
                        <select id="secondaryThemeColor" class="form-select form-select-table">
                            <option value="">Select a color</option>
                            <option value="red">Red</option>
                            <option value="blue">Blue</option>
                            <option value="green">Green</option>
                            <option value="yellow">Yellow</option>
                        </select>
                    </div>
                </div>
                        <div class="col-lg-12 my-2">
                          <h4 class="mt-2 mb-0">Affiliate and Associate</h4>
                          <hr class="my-1">
                        </div>
                        <div class="col-lg-6 my-2">
                      <label class="col-form-label">Affiliate/Associate Name</label>
                      <input type="text" class="form-control" placeholder="Enter Affiliate Name" />
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Type of Affiliation</label>
                      <select class="form-select form-select-table">
                        <option value="">Select Type</option>
                        <option value="vendor">Vendor</option>
                        <option value="partner">Partner</option>
                        <option value="supplier">Supplier</option>
                      </select>
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Contact Details</label>
                      <input type="text" class="form-control" placeholder="Enter Contact Details" />
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Service Areas</label>
                      <input type="text" class="form-control" placeholder="Enter Contact Details" />
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Onboarding Status</label>
                      <select class="form-select form-select-table">
                        <option value="">Select Status</option>
                        <option value="inProgress">In Progress</option>
                        <option value="completed">Completed</option>
                        <option value="pending">Pending</option>
                      </select>
                    </div>
                    <div class="col-lg-6 my-2">
                      <label class="col-form-label">Agreement Signed</label>
                      <select class="form-select form-select-table">
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>               
                <div class="col-lg-12 mt-2 d-flex justify-content-lg-end">
                  <button class="save_btn py-1 px-4" type="button">Save</button></div>
              </div>
            </div>
          <!-- tab 1 end -->
          <!-- tab 2 start -->
          <div v-else-if="index === 1">
              <div class="form-group row">
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label for="defaultStudyParameters">Default Study Parameters</label>
                        <select id="defaultStudyParameters" class="form-select form-select-table">
                            <option value="">Select Study Parameter</option>
                            <option value="parameter1">Randomization</option>
                            <option value="parameter2">Blinding/Masking</option>
                            <option value="parameter3">Crossover Design</option>
                            <option value="parameter4">Open-Label Study</option>
                        </select>
                  </div>
                </div>
                <div class="col-lg-4 my-2">
                    <div class="form-group">
                        <label for="standardOperatingProcedures">Standard Operating Procedures</label>
                        <select id="standardOperatingProcedures" class="form-select form-select-table">
                            <option value="">Select SOP</option>
                            <option value="sop1">Participant Recruitment and Screening</option>
                            <option value="sop2">Informed Consent Process</option>
                            <option value="sop3">Clinical Data Collection and Management</option>
                            <option value="sop4">Safety Monitoring and Adverse Event Reporting</option>
                            <option value="sop5">Site Selection and Initiation</option>
                            <option value="sop6">Regulatory Compliance and Submissions</option>
                            <option value="sop7">Study Protocol Development and Amendments</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label for="clinicalTrialStandards">Clinical Trial Standards</label>
                        <select id="clinicalTrialStandards" class="form-select form-select-table">
                            <option value="">Select Trial Standard</option>
                            <option value="standard1">FDS</option>
                            <option value="standard2">ICH-GCP</option>
                            <option value="standard3">HIPAA</option>
                            <option value="standard4">HHS Regulations</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-12 my-2">
                    <h4 class="mt-2 mb-0">Service Scope</h4>
                    <hr class="my-1">
                </div>
                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Service Category</label>
                  <select class="form-select form-select-table">
                    <option value="">Select Service Category</option>
                    <option value="monitoring">Monitoring</option>
                    <option value="dataManagement">Data Management</option>
                    <option value="siteSelection">Site Selection</option>
                    <option value="regulatory">Regulatory Submissions</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Study Phase</label>
                  <select class="form-select form-select-table">
                    <option value="">Select Study Phase</option>
                    <option value="phase1">Phase I</option>
                    <option value="phase2">Phase II</option>
                    <option value="phase3">Phase III</option>
                    <option value="phase4">Phase IV</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Data Collection Method</label>
                  <select class="form-select form-select-table">
                    <option value="">Select Method</option>
                    <option value="ecrf">eCRF</option>
                    <option value="paperBased">Paper-Based</option>
                    <option value="hybrid">Hybrid</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Regions of Operation</label>
                  <select class="form-select form-select-table">
                    <option value="">Select Region</option>
                    <option value="northAmerica">North America</option>
                    <option value="europe">Europe</option>
                    <option value="asia">Asia</option>
                    <option value="global">Global</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Reporting Requirements</label>
                  <input type="text" class="form-control" placeholder="Enter Requirements" />
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Risk Management Plan</label>
                  <select class="form-select form-select-table">
                    <option value="">Select Plan Type</option>
                    <option value="basic">Basic</option>
                    <option value="advanced">Advanced</option>
                    <option value="custom">Custom</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Quality Assurance Checks</label>
                  <select class="form-select form-select-table">
                    <option value="">Select Frequency</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>

                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Service Timeline (Months)</label>
                  <input type="number" class="form-control" placeholder="Enter Duration" />
                </div>
                <div class="col-lg-12 my-2">
                    <h4 class="mt-2 mb-0">EHR Data Management</h4>
                    <hr class="my-1">
                </div>
                <div class="col-lg-6 my-2">
                    <label for="uploadFile">Upload EHR Data File</label>
                    <input type="file" id="uploadFile" class="form-control" accept=".pdf, .txt"/>
                    <!-- <small class="form-text text-muted">Supports Excel/CSV/JSON.</small> -->
                </div>
                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Data Cleaning Preferences</label>
                  <select class="form-select form-select-table">
                    <option value="">Select</option>
                    <option value="daily">Automated</option>
                    <option value="weekly">Manual</option>
                  </select>
                </div>
                <div class="col-lg-6 my-2">
                  <label class="col-form-label">Data Standards</label>
                  <select class="form-select form-select-table">
                      <option value="">Select</option>
                      <option value="HL7">HL7 (Health Level Seven)</option>
                      <option value="FHIR">FHIR (Fast Healthcare Interoperability Resources)</option>
                      <option value="CDISC">CDISC (Clinical Data Interchange Standards Consortium)</option>
                      <option value="SDTM">SDTM (Study Data Tabulation Model)</option>
                      <option value="ADaM">ADaM (Analysis Data Model)</option>
                      <option value="ODM">ODM (Operational Data Model)</option>
                      <option value="eCRF">eCRF (Electronic Case Report Form Standards)</option>
                      <option value="LOINC">LOINC (Logical Observation Identifiers Names and Codes)</option>
                      <option value="SNOMED_CT">SNOMED CT (Systematized Nomenclature of Medicine - Clinical Terms)</option>
                      <option value="ICD_10_11">ICD-10/ICD-11 (International Classification of Diseases)</option>
                      <option value="CPT">CPT (Current Procedural Terminology)</option>
                      <option value="RxNorm">RxNorm (Standardized Naming System for Medications)</option>
                      <option value="UMLS">UMLS (Unified Medical Language System)</option>
                      <option value="HIPAA">HIPAA (Health Insurance Portability and Accountability Act - Data Privacy)</option>
                      <option value="DICOM">DICOM (Digital Imaging and Communications in Medicine)</option>
                      <option value="GUDID">GUDID (Global Unique Device Identification Database)</option>
                      <option value="ISO_IEEE_11073">ISO/IEEE 11073 (Medical Device Communication Standards)</option>
                      <option value="BRIDG">BRIDG (Biomedical Research Integrated Domain Group Model)</option>
                      <option value="MedDRA">MedDRA (Medical Dictionary for Regulatory Activities)</option>
                      <option value="CCDA">CCDA (Consolidated Clinical Document Architecture)</option>
                      <option value="E2B">E2B (ICH Guideline for Electronic Transmission of Individual Case Safety Reports)</option>
                      <option value="OMOP">OMOP (Observational Medical Outcomes Partnership Common Data Model)</option>
                  </select>
                </div>
                <div class="col-lg-12 mt-2 d-flex justify-content-lg-end">
                  <button class="save_btn py-1 px-4" type="button">Save</button></div>
                </div>
            </div>
            <!-- tab 2 end -->
            <!-- tab 3 start -->
            <div v-else-if="index === 2">
              <div class="form-group row">
                <div class="col-lg-12 my-2">
                  <label class="col-form-label">Billing Address</label>
                  <input type="text" class="form-control"  />
                </div>
                <div class="col-lg-4 my-2">
                  <label class="col-form-label">Tax ID/Registration Number</label>
                  <input type="text" class="form-control"  />
                </div>
                <div class="col-lg-4 my-2">
                    <div class="form-group">
                        <label class="col-form-label">Payment Terms</label>
                        <select id="standardOperatingProcedures" class="form-select form-select-table">
                            <option value="">Select</option>
                            <option value="sop1">Net 30</option>
                            <option value="sop2">Net 60</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-4 my-2">
                  <div class="form-group">
                        <label class="col-form-label">Preferred Currency</label>
                        <select id="clinicalTrialStandards" class="form-select form-select-table">
                            <option value="">Select</option>
                            <option value="standard1">USD</option>
                            <option value="standard2">EUR</option>
                            <option value="standard3">GBP</option>
                            <option value="standard4">INR</option>
                        </select>
                    </div>
                </div>
                <div class="col-lg-12 mt-2 d-flex justify-content-lg-end">
                  <button class="save_btn py-1 px-4" type="button">Save</button></div>
                </div>
            </div>   
            <!-- tab 3 end -->
             <!-- Protocol Development Tab -->
                  <div v-else-if="index === 3">
                    <div v-if="!protocolView">
                      <div class="d-flex justify-content-between">
                        <h4>Protocol Development</h4>
                      </div>
                      <div class="row">
                        <div class="col-lg-12 my-2">
                          <div class="row">
                              <!-- Total Protocols -->
                              <div class="col-lg-3 col-md-6">
                                <div class="widget-card bg-primary">
                                  <div class="widget-icon">
                                    <i class="fa fa-list-alt"></i>
                                  </div>
                                  <div class="widget-content">
                                    <h3>{{ totalProtocols }}</h3>
                                    <p>Total Protocols</p>
                                  </div>
                                </div>
                              </div>

                              <!-- Approved Protocols -->
                              <div class="col-lg-3 col-md-6">
                                <div class="widget-card bg-success">
                                  <div class="widget-icon">
                                    <i class="fa fa-check-circle"></i>
                                  </div>
                                  <div class="widget-content">
                                    <h3>{{ approvedProtocols }}</h3>
                                    <p>Approved Protocols</p>
                                  </div>
                                </div>
                              </div>

                              <!-- Pending Protocols -->
                              <div class="col-lg-3 col-md-6">
                                <div class="widget-card bg-warning">
                                  <div class="widget-icon">
                                    <i class="fa fa-clock-o"></i>
                                  </div>
                                  <div class="widget-content">
                                    <h3>{{ pendingProtocols }}</h3>
                                    <p>Pending Approvals</p>
                                  </div>
                                </div>
                              </div>

                              <!-- Rejected Protocols -->
                              <div class="col-lg-3 col-md-6">
                                <div class="widget-card bg-danger">
                                  <div class="widget-icon">
                                    <i class="fa fa-times-circle"></i>
                                  </div>
                                  <div class="widget-content">
                                    <h3>{{ rejectedProtocols }}</h3>
                                    <p>Rejected Protocols</p>
                                  </div>
                                </div>
                              </div>

                            </div>

                            <!-- Progress Overview -->
                            <div class="ibox-content mt-3">
                              <h4>Protocol Progress</h4>
                              <div class="progress">
                                <div class="progress-bar bg-info" role="progressbar"
                                    :style="{ width: avgProgress + '%' }">
                                  {{ avgProgress }}%
                                </div>
                              </div>
                            </div>
                        </div>
                        <div class="col-lg-4 my-2">
                          <button class="btn btn-primary w-100" @click="protocolView = 'create'">
                            Create New Protocol
                          </button>
                        </div>
                        <div class="col-lg-4 my-2">
                          <button class="btn btn-secondary w-100" @click="protocolView = 'import'">
                            Import from Previous Protocol
                          </button>
                        </div>
                        <div class="col-lg-4 my-2">
                          <button class="btn btn-warning w-100" @click="protocolView = 'query'">
                            Query Section
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Create New Protocol -->
                    <div v-if="protocolView === 'create'">
                      <div class="d-flex justify-content-between">
                        <h4>Create New Protocol</h4>
                        <button class="btn btn-close-red" @click="protocolView = null"><i class="fa fa-times"></i></button>
                      </div>
                        <div class="wrapper wrapper-content">
                          <div class="row">
                                <div class="col-lg-12">
                                  <div class="table-responsive">
                        <!-- Dropdown for Sections -->
                        <div class="form-group">
                          <label for="sectionDropdown">Select Section</label>
                          <select id="sectionDropdown" class="form-select" v-model="selectedSection">
                            <option value="">Select Section</option>
                            <option v-for="section in sections" :key="section.id" :value="section.id">
                              {{ section.name }}
                            </option>
                          </select>
                        </div>

                        <!-- Dynamic Section Content -->
                        <div v-if="selectedSection">
    <div v-for="section in sections" :key="section.id">
      <div v-if="section.id === selectedSection">
        <div class="ibox-title section_head_1">
          <h5>{{ section.name }}</h5>
          <div class="ibox-tools">
            <button class="btn btn-default btn-sm px-3 py-0 float-right" @click="openAddRowPopup(section)">
              <i class="fa fa-plus-circle"></i> Add Row
            </button>
          </div>
        </div>
        <table class="table table-striped table-bordered dataTables mb-2">
          <thead>
            <tr>
              <th>S.No</th>
              <th>Section</th>
              <th>Role 1</th>
              <th>Role 2</th>
              <th>Role 3</th>
              <th>Role 4</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in section.rows" :key="index">
              <td>{{ row.sno }}</td>
              <td>{{ row.section }}</td>
              <td>
                <select class="form-select form-select-table">
                  <option value="">Select</option>
                  <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
                </select>
              </td>
              <td>
                <select class="form-select form-select-table">
                  <option value="">Select</option>
                  <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
                </select>
              </td>
              <td>
                <select class="form-select form-select-table">
                  <option value="">Select</option>
                  <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
                </select>
              </td>
              <td>
                <select class="form-select form-select-table">
                  <option value="">Select</option>
                  <option v-for="role in roles" :key="role" :value="role">{{ role }}</option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-primary btn-sm px-3 py-0 float-right mb-2">Submit</button>
      </div>
    </div>
  </div>

  <!-- Add Row Modal -->
  <div v-if="showAddRowModal" class="modal fade show" style="display: block; background: rgba(0, 0, 0, 0.8);">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add New Section Row</h5>
          <button type="button" class="close" @click="closeAddRowModal" aria-label="Close">
            <i class="fa fa-times"></i>
          </button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="handleAddRowSubmit">
            <div class="form-group">
              <label>Section Name</label>
              <input type="text" class="form-control" v-model="newRow.name" placeholder="Enter section name" required>
            </div>
            <div class="form-group">
              <label>Parent Section</label>
              <select class="form-control" v-model="newRow.parentSno">
                <option value="">Main Section (Top Level)</option>
                <option v-for="row in currentSectionRows" :value="row.sno" :key="row.sno">
                  {{ row.sno }} - {{ row.section }}
                </option>
              </select>
            </div>
            <div class="form-group">
              <label>Position</label>
              <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                <label class="btn btn-outline-primary px-3 py-0" :class="{ 'active': newRow.position === 'child' }">
                  <input type="radio" v-model="newRow.position" value="child"> Sub Section
                </label>
                <label class="btn btn-outline-primary px-3 py-0" :class="{ 'active': newRow.position === 'after' }">
                  <input type="radio" v-model="newRow.position" value="after"> After
                </label>
                <label class="btn btn-outline-primary px-3 py-0" :class="{ 'active': newRow.position === 'before' }">
                  <input type="radio" v-model="newRow.position" value="before"> Before
                </label>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary btn-sm px-3 py-0 float-right mb-2" @click="closeAddRowModal">Cancel</button>
              <button type="submit" class="btn btn-primary btn-sm px-3 py-0 float-right mb-2">Add Row</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
                      </div>
                                  
                            </div>
                          </div>
                        </div>
                    </div>

                    <div v-if="protocolView === 'import'">
                      <div class="d-flex justify-content-between">
                        <h4>Import from Previous Protocol</h4>
                        <button class="btn btn-close-red" @click="protocolView = null"><i class="fa fa-times"></i></button>
                      </div>
                      <select class="form-select my-2">
                        <option value="">Select Previous Protocol</option>
                        <option value="protocol1">Protocol 1</option>
                        <option value="protocol2">Protocol 2</option>
                      </select>
                      <button class="btn btn-success">Import</button>
                    </div>

                    <div v-if="protocolView === 'query'">
                      <div class="d-flex justify-content-between">
                        <h4>Protocol Query Section</h4>
                        <button class="btn btn-close-red" @click="protocolView = null"><i class="fa fa-times"></i></button>
                      </div>
                      <textarea class="form-control my-2" placeholder="Enter your query..."></textarea>
                      <button class="btn btn-primary">Submit Query</button>
                    </div>
                  </div>

              </div>
          </div>
          <div class="modal" tabindex="-1" role="dialog" v-if="selectedClient">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Client Details</h5>
                  <button type="button" class="close" @click="closeModal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p><strong>Name:</strong> {{ selectedClient.name }}</p>
                  <p><strong>Email:</strong> {{ selectedClient.email }}</p>
                  <p><strong>Phone:</strong> {{ selectedClient.phone }}</p>
                  <p><strong>Address:</strong> {{ selectedClient.address }}</p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" @click="closeModal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import studyOnboarding from "./studyOnboarding.js";
  
  export default {
    name: "studyOnboarding",
    data() {
      return {
      newRow: {
      name: '',
      parentSno: '',
      position: 'child'
    },
    showAddRowModal: false,
    currentSectionRows: [],
        tabs: [
          { label: "General Information", content: "navigations.tab1_content" },
          { label: "Study Configuration", content: "navigations.tab2_content" },
          { label: "Billing", content: "navigations.tab3_content" },
          { label: "Protocol Development", content: "navigations.tab4_content" },
        ],
        showBlock: [true, false, false, false],
        protocolView: null,
        protocols: [],
        selectedSection: null,
        sections: [
          {
            id: 1,
            name: "1. INSTITUTIONAL REVIEW BOARD/INDEPENDENT ETHICS COMMITTEE (IRB/IEC)",
            rows: [
              { sno: 1, section: "INSTITUTIONAL REVIEW BOARD/INDEPENDENT ETHICS COMMITTEE (IRB/IEC)" },
            ],
          },
          {
            id: 2,
            name: "2. INVESTIGATOR",
            rows: [
              { sno: "2", section: "INVESTIGATOR" },
              { sno: "2.1", section: "Qualifications and Training" },
              { sno: "2.2", section: "Resources" },
              { sno: "2.3", section: "Responsibilities" },
              { sno: "2.4", section: "Communication with IRB/IEC" },
              { sno: "2.5", section: "Compliance with Protocol" },
              { sno: "2.6", section: "Premature Termination or Suspension of a Trial" },
              { sno: "2.7", section: "Participant Medical Care and Safety Reporting" },
              { sno: "2.7.1", section: "Medical Care of Trial Participants" },
              { sno: "2.7.2", section: "Safety Reporting" },
              { sno: "2.8", section: "Informed Consent of Trial Participants" },
              { sno: "2.9", section: "End of Participation in a Clinical Trial" },
              { sno: "2.10", section: "Investigational Product Management" },
              { sno: "2.11", section: "Randomisation Procedures and Unblinding" },
              { sno: "2.12", section: "Records" },
              { sno: "2.13", section: "Clinical Trial/Study Reports" },
            ],
          },
          {
            id: 3,
            name: "3. SPONSOR",
            rows: [
              { sno: "3", section: "SPONSOR"},
              { sno: "3.1", section: "Trial Design"},
              { sno: "3.2", section: "Resources"},
              { sno: "3.3", section: "Allocation of Activities"},
              { sno: "3.4", section: "Qualification and Training"},
              { sno: "3.4.1", section: "Medical Expertise"},
              { sno: "3.5", section: "Financing"},
              { sno: "3.6", section: "Agreements"},
              { sno: "3.7", section: "Investigator Selection"},
              { sno: "3.8", section: "Communication with IRB/IEC and Regulatory Authority(ies)"},
              { sno: "3.8.1", section: "Notification/Submission to Regulatory Authority(ies)"},
              { sno: "3.8.2", section: "Confirmation of Review by IRB/IEC"},
              
            ],
          },
          {
            id: 4,
            name: "4. DATA GOVERNANCE – INVESTIGATOR AND SPONSOR",
            
            rows: [
                { sno: "4.1", section: "Safeguard Blinding in Data Governance" },
                { sno: "4.2", section: "Data Life Cycle Elements" },
                { sno: "4.2.1", section: "Data Capture" },
                { sno: "4.2.2", section: "Relevant Metadata, Including Audit Trails" },
                { sno: "4.2.3", section: "Review of Data and Metadata " },
                { sno: "4.2.4", section: "Data Corrections" },
                { sno: "4.2.5", section: "Data Transfer, Exchange and Migration" },
                { sno: "4.2.6", section: "Finalisation of Data Sets Prior to Analysis" },
                { sno: "4.3", section: "Computerised Systems" },
                { sno: "4.3.1", section: "Procedures for the Use of Computerised Systems" },
                { sno: "4.3.2", section: "Training" },
            ],
          },
        ],
        roles: [
          "Protocol Prime Writer",
          "Project Manager (Country Specific)",
          "Project Manager (Sponsor)",
          "Project Manager (QA)",
          "Project Manager (DM)",
          "Project Manager (CRO)",
          "Project Manager (computer systems)",
          "Project Manager",
          "Medical Affairs Manager",
          "Monitor",
          "Sponsor Project manager",
          "Project Manager (Accounts)",
          "Project Manager (Legal)",
          "Project Manager (IMP Management)",
          "Project Manager (Statistical)",
          "Project Manager (IVRS Specific)",
        ],
      };
    },
    computed: {
      filteredTabs() {
        return this.tabs;
      },
      totalProtocols() {
        return this.protocols.length;
      },
      approvedProtocols() {
        return this.protocols.filter((p) => p.status === "Approved").length;
      },
      pendingProtocols() {
        return this.protocols.filter((p) => p.status === "Pending").length;
      },
      rejectedProtocols() {
        return this.protocols.filter((p) => p.status === "Rejected").length;
      },
      avgProgress() {
        const total = this.protocols.reduce((sum, p) => sum + p.progress, 0);
        return total > 0 ? Math.round(total / this.protocols.length) : 0;
      },
    },
    methods: {
      openAddRowPopup(section) {
        this.selectedSection = section.id;
        this.currentSectionRows = section.rows;
        this.showAddRowModal = true;
        
      },
      closeAddRowModal() {
        this.showAddRowModal = false;
        this.newRow = {
          name: '',
          parentSno: '',
          position: 'child'
        };
        
      },
      handleAddRowSubmit() {
      const section = this.sections.find(s => s.id === this.selectedSection);
      const newSno = this.generateNewSno();

      const newRow = {
        sno: newSno,
        section: this.newRow.name
      };

      if (this.newRow.parentSno) {
        const parentIndex = section.rows.findIndex(row => row.sno === this.newRow.parentSno);
        if (parentIndex !== -1) {
          if (this.newRow.position === 'child') {
            // Insert as a child
            section.rows.splice(parentIndex + 1, 0, newRow);
          } else if (this.newRow.position === 'after') {
            // Insert after the parent
            section.rows.splice(parentIndex + 1, 0, newRow);
          } else if (this.newRow.position === 'before') {
            // Insert before the parent
            section.rows.splice(parentIndex, 0, newRow);
          }
        }
      } else {
        // Insert at the top level
        section.rows.push(newRow);
      }

      this.updateNumbering(section.rows);
      this.closeAddRowModal();
    },

      generateNewSno() {
        if (!this.newRow.parentSno) {
          // Main section numbering
          const sectionNumber = this.selectedSection;
          const existingMain = this.currentSectionRows.filter(r => r.sno.split('.').length === 1);
          return `${sectionNumber}.${existingMain.length + 1}`;
        }

        const parentParts = this.newRow.parentSno.split('.');
        
        // Child numbering
        if (this.newRow.position === 'child') {
          const children = this.currentSectionRows.filter(r => 
            r.sno.startsWith(this.newRow.parentSno + '.') &&
            r.sno.split('.').length === parentParts.length + 1
          );
          return `${this.newRow.parentSno}.${children.length + 1}`;
        }

        // Sibling numbering
        const siblings = this.currentSectionRows.filter(r => 
          r.sno.startsWith(parentParts.slice(0, -1).join('.')) &&
          r.sno.split('.').length === parentParts.length
        );
        
        const lastPart = parseInt(parentParts.slice(-1));
        return this.newRow.position === 'after' 
          ? `${parentParts.slice(0, -1).join('.')}.${lastPart + 1}`
          : `${parentParts.slice(0, -1).join('.')}.${lastPart}`;
      },

          updateNumbering(rows) {
          rows.forEach((row, index) => {
            row.sno = index + 1;
          });
          },

          setActiveTab(index) {
            this.showBlock = this.showBlock.map((_, i) => i === index);
            if (index === 3) {
              this.loadProtocolData();
            }
          },
          async callstudyOnboarding() {
            const response = await studyOnboarding.someFunction();
            console.log(response);
          },
          async loadProtocolData() {
            this.protocols = [
              { id: 1, status: "Approved", progress: 80 },
              { id: 2, status: "Pending", progress: 40 },
              { id: 3, status: "Rejected", progress: 20 },
              { id: 4, status: "Approved", progress: 90 },
            ];
          },
        },
        mounted() {
          this.callstudyOnboarding();
        },
      };
      </script>
  

  
<style scoped>

  .nav-tabs {
      border-bottom: 1px solid #6660fe;
  }
  .nav-tabs li a {
    padding: 5px 20px;
    cursor: pointer;
    border: solid 0px #ffffff;
    border-radius: 10px 10px 0px 0px;
  }
  
  .activeTab {
    color: #ffffff !important;
    background-color: #6660fe;
    border-color: #6660fe;
  }
  
  .ibox-title {
    background-color: #3c4192;
    color: rgb(95, 95, 95);
    padding: 10px;
  }
  
  .ibox-content {
    padding: 15px;
    background-color: #ffffff;
    border: 1px solid #ddd;
  }
  .form-select{
    border-radius: .5rem !important;
    font-family: .8rem !important;
  }
  .table th,
  .table td {
    vertical-align: middle;
  }
.btn-close-red{
  position: relative;
    width: 25px;
    height: 25px;
    padding: 3px 0;
    border-radius: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 200;
    line-height: 1.428571429;
    border: 0px solid #ED5565;
}

.btn-close-red{
    color: #fff;
    background-color: #ED5565;
    border-color: #ED5565;
}
.widget-card {
  padding: 10px 
  20px;
  color: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.widget-card p {
font-size: 16px;
font-weight: 600;
}

.widget-content {
  text-align: right;
}

.widget-icon {
  font-size: 40px;
}

/* Background Colors */
.bg-primary { background-color: #1c84c6 !important; }
.bg-success { background-color: #1ab394 !important; }
.bg-warning { background-color: #f8ac59 !important; }
.bg-danger { background-color: #ED5565 !important; }
.bg-info { background-color: #23c6c8 !important; }

/* Progress Bar */
.progress {
  height: 20px;
  border-radius: 10px;
}
/* tree structure*/ 
.tree {
  list-style-type: none;
  padding-left: 10px;
}

.parent-node, .child-node {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.parent-node i, .child-node i {
  margin-right: 8px;
  color: #007bff;
}

.sub-child-node {
  margin-left: 20px;
  color: #555;
}
.kebab-btn {
  background: none;
  border: none;
  cursor: pointer;
  margin-left: auto;
}

.context-menu {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  list-style: none;
  padding: 5px;
}

.context-menu li {
  padding: 5px;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border: 1px solid #ccc;
  display: block;
}
.modal-header h5{
 font-size: 14px !important; 
 font-size: 600 !important;
}
.modal-header{
 padding: .5rem 1rem !important;
}

.close {
  float: right;
  cursor: pointer;
}
.node-container::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 50%;
  width: 10px;
  border-bottom: 1px dotted #aaa;
}
.node-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}
/*Table*/
.form-select-table{
  font-size: 0.8rem !important;
  padding: 6px 6px !important;
  background-position: right 0.50rem center !important;
  background-size: 8px 10px !important;
}
.dataTables {
        width: 100%;
        table-layout: fixed; /* Ensures columns respect width constraints */
    }

    /* Fix column widths */
    .dataTables th, .dataTables td {
        text-align: left;
        padding: 2px 4px;
        vertical-align: middle;
    }

    .dataTables th:nth-child(1), .dataTables td:nth-child(1) { width: 4%; background-color: #eae9ff;} /* S.No */
    .dataTables th:nth-child(2), .dataTables td:nth-child(2) { 
        width: 32%; /* Section Name */
        word-wrap: break-word; 
        white-space: normal; 
    }
    .dataTables th:nth-child(3), .dataTables td:nth-child(3),
    .dataTables th:nth-child(4), .dataTables td:nth-child(4),
    .dataTables th:nth-child(5), .dataTables td:nth-child(5),
    .dataTables th:nth-child(6), .dataTables td:nth-child(6) { 
        width: 16%; /* Role columns */
        
    }

    /* Ensure dropdowns are properly aligned */
    .form-select {
        min-width: 100px;  /* Prevents dropdown from shrinking too much */
        max-width: 100%;   /* Ensures dropdown stays within its cell */
        word-wrap: break-word;
        white-space: normal;
    }
    .btn-default {
    color: inherit;
    background: white;
    border: 1px solid #babbbb;
    }
    .section_head_1 {
    background-color: #ffffff !important;
    border-radius: 0px !important;
    min-height: 36px;
}
  </style>  